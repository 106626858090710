import { survey } from './survey'
import { assets } from './assets'
import { mapStyle } from './map'
import { serviceChangeFields } from '../shared/search'
import React from 'react'
import { ReactComponent as MyClosestFeedIcon } from './assets/images/cutlery.svg'
import { ReactComponent as AccessTheListIcon } from './assets/images/cutleryPin.svg'
import { ReactComponent as MySavedListIcon } from './assets/images/forkHeart.svg'
import { ReactComponent as WelfareServiceProviderIcon } from './assets/images/handShake.svg'

const directoryURL = window.REACT_APP_DIRECTORY_URL
// const diayUrl = window.REACT_APP_DIARY_URL
// const cieApiUrl = window.REACT_APP_CIE_API_URL

const config = {
  id: 'vaks',

  options: {
    // title: 'VAKS Vans And Kitchens',
    // title: 'Vans And Kitchens',
    categoryMode: 'single',
    combineMode: 'categories',
    defaultCategoryTitle: 'Food Vans',
    defaultCategorySuffix: '',
  },

  analytics: {
    app: 'vaks',
    plugins: [
      // {
      //   provider: 'google-analytics',
      //   // trackingId: 'UA-188798033-1', // AMS Test
      //   trackingId: 'UA-147876591-7',
      //   siteSpeedSampleRate: 100,
      // },
      {
        provider: 'cie-elastic',
        index: 'prod-ams-vaks-analytics',
      },
    ],
    googleAnalytics: {
      isGA4: true,
      containerId: 'GTM-KJJHFCF',
    },
  },

  meta: {
    templates: {
      title: '',
      titlePrefix: 'VAKS Vans And Kitchens',
      description:
        'Your one-stop online national directory to finding a Van or a Kitchen for anyone needing a feed and some TLC near you. Powered by the Community Information Exchange, My Community Directory and My Community Diary.',
    },
  },

  routeOptions: {
    enableSearchRouteHandler: true,
    pathOptions: {
      delimiter: '+',
    },
    parameterMaps: {
      state: {
        state: {
          source: 'state',
          map: ['longUrl'],
        },
      },
      council: {
        state: {
          source: 'council',
          map: ['stateUrl'],
        },
        council: {
          source: 'council',
          map: ['councilUrl'],
        },
      },
      locality: {
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      search: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
          multiple: ['urlFormatted', 'longUrl'],
        },
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      categories: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
        },
      },
    },

    generatedPaths: {
      // root: {
      //   path: '/',
      //   parameterMaps: ['search'],
      // },
      about: {
        path: '/about',
        canonical: '/about',
      },
      support: {
        path: '/support',
        canonical: '/support',
      },
      regions: {
        path: '/regions/',
        canonical: '/regions',
      },
      categories: {
        path: '/categories/',
        canonical: '/categories',
      },
      browseState: {
        path: '/regions/:state',
        linkType: 'browse',
        parameterMaps: ['state'],
      },
      browseCouncil: {
        path: '/regions/:state/:council',
        linkType: 'browse',
        parameterMaps: ['council'],
      },
      browseLocality: {
        path: '/regions/:state/:council/:locality',
        linkType: 'browse',
        parameterMaps: ['locality'],
      },
      browseCategory: {
        path: '/categories/:categories',
        linkType: 'browse',
        parameterMaps: ['categories'],
      },
      searchCategories: {
        path: '/search/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocality: {
        path: '/search/:state/:council/:locality',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocalityCategories: {
        path: '/search/:state/:council/:locality/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
    },
  },

  routes: [
    {
      id: 'home',
      path: '/',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'home',
      },
    },
    {
      id: 'search',
      path: [
        '/search',
        '/search/categories/:categories',
        '/search/:state/:council/:locality',
        '/search/:state/:council/:locality/categories/:categories',
      ],
      exact: true,
      component: 'Search',
      meta: {
        views: ['searchRoute'],
        templates: {
          title:
            '{{ search.categoriesSummary }} in {{ search.locationSummary }}',
          description:
            'Search for {{ search.categoriesSummary }} in {{ search.locationSummary }}. Powered by the Community Information Exchange, My Community Directory and My Community Diary.',
        },
      },
      pathConfig: {
        default: {
          redirectToStored: false,
        },
        root: {
          redirectToStored: true,
        },
      },
    },
    {
      path: '/about',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'about',
      },
      meta: {
        templates: {
          title: 'About Us',
        },
      },
    },
    {
      path: '/support',
      exact: true,
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        templates: {
          title: 'Site Support',
        },
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        templates: {
          title: '404 Not Found',
        },
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],

  categories: [
    {
      id: 'food-vans',
      title: 'Food Vans',
      search: true,
      categories: [161],
    },
  ],

  search: {
    // // this is hacky
    // router: {
    //   root: '/',
    //   location: {
    //     prefix: 'search',
    //     format: 'state/council/locality',
    //   },
    //   category: {
    //     prefix: 'category',
    //     field: 'url',
    //     delimiter: '+',
    //   },
    // },
    location: {
      showLocationDialog: true,
      initLocation: 'dialog',
    },

    queries: [
      {
        id: 'services',
        builder: 'directory',
        parameters: [
          { param: 'page' },
          { param: 'sort', sortField: 'Distance', sortDirection: 'ASC' },
          { param: 'query' },
          { param: 'categories' },
          { param: 'location' },
          { param: 'radius' },
        ],
      },
      {
        id: 'events',
        builder: 'diary',
        parameters: [
          { param: 'page' },
          { param: 'query' },
          { param: 'categories' },
          { param: 'locationId' },
          { param: 'radius' },
          { param: 'dateRange' },
        ],
      },
    ],

    searches: {
      services: {
        id: 'services',
        type: 'directory',
        pageSize: 100,
        showSiteSupport: true,
      },
      events: {
        id: 'events',
        type: 'diary',
        pageSize: 30,
        showSiteSupport: true,
        options: {
          uniqueEvents: true,
        },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
  },

  ui: {
    appBar: {
      elevation: 0,
      // position: 'static',
      groups: [
        {
          align: 'left',
          items: [
            {
              component: 'MenuButton',
              props: {
                sx: { px: 2 },
              },
            },
            {
              component: 'AppBarTitle',
              props: {
                // disableTextTitle: true,
                to: '/',
              },
            },
          ],
        },
        {
          align: 'fill',
          items: [],
        },
        {
          align: 'right',
          items: [
            {
              component: 'AppBarButton',
              media: [{ query: 'up', breakpoints: ['md'], prop: 'render' }],
              props: {
                icon: 'fas fa-arrow-to-top',
                label: 'Register',
                href: `${directoryURL}/register`,
                target: '_blank',
                variant: 'text',
                color: 'primary',
                disableElevation: true,
              },
            },
            {
              component: 'AppBarButton',
              media: [{ query: 'up', breakpoints: ['md'], prop: 'render' }],
              props: {
                icon: 'fas fa-sign-in',
                label: 'Sign In',
                href: `${directoryURL}/Account/Login?loginfrom=www.vaks.org.au`,
                target: '_blank',
                variant: 'text',
                color: 'primary',
                disableElevation: true,
              },
            },
          ],
        },
      ],
    },
    homeButtons: {
      mode: 'row',
      iconImgSet: {
        'my-closest-feed': {
          src: <MyClosestFeedIcon style={{ height: '90px', width: '90px' }} />,
          color: 'white',
          sx: { height: '80px', color: '#feda3b', borderRadius: '10px' },
        },
        'access-the-list': {
          src: <AccessTheListIcon style={{ height: '90px', width: '90px' }} />,
          color: 'white',
          sx: { height: '80px' },
        },
        'my-saved-list': {
          src: (
            <MySavedListIcon
              style={{ height: '80px', width: '80px', marginRight: '22px' }}
            />
          ),
          color: 'white',
          sx: { height: '80px' },
        },
        'welfare-service-providers': {
          src: (
            <WelfareServiceProviderIcon
              style={{ height: '90px', width: '90px', marginRight: '20px' }}
            />
          ),
          color: 'white',
          sx: {},
        },
      },
      buttons: [
        {
          id: 'my-closest-feed',
          icon: 'fas fa-user',
          loadiconWithId: true,
          color: 'hbGrey',
          label: 'My Closest Feed is What I Need',
          route: '/search',
          toTab: 0,
          btnStyle: '50px',
        },
        {
          id: 'welfare-service-providers',
          color: 'hbGrey',
          icon: 'fas fa-plus-square',
          loadiconWithId: true,
          label: 'Find Welfare Service Providers',
          route:
            'https://www.mycommunitydirectory.com.au/info/VAKS-Welfare-Service-Providers',
          btnStyle: '50px',
        },
        {
          id: 'access-the-list',
          color: 'hbGrey',
          icon: 'fas fa-user-friends',
          loadiconWithId: true,
          label: 'Access The List - Find a Feed in Your Area',
          route:
            'https://www.mycommunitydirectory.com.au/info/VAKS-Find-a-feed-in-your-area',
          btnStyle: '50px',
        },
      ],
    },

    footer: {
      sections: [
        {
          theme: 'variants.footer',
          blocks: [
            {
              component: 'CieBlock',
              gridProps: { xs: 12, md: 8 },
            },
            {
              title: {
                label: 'Menu',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'navigation',
                hide: 'footer',
              },
            },
            // {
            //   title: {
            //     label: 'Contact',
            //     divider: true,
            //   },
            //   component: 'StaticMenu',
            //   gridProps: { xs: 12, md: 4 },
            //   props: {
            //     menu: 'contact',
            //     hide: 'footer',
            //   },
            // },
          ],
        },
        // {
        //   theme: 'variants.footerAtsi',
        //   blocks: [
        //     {
        //       component: 'Acknowledgement',
        //     },
        //   ],
        // },
      ],
    },

    menus: {
      navigation: {
        items: [
          {
            id: 'home',
            label: 'Home',
            icon: 'fas fa-home-alt',
            route: '/',
          },
          // {
          //   id: 'regions',
          //   label: 'Browse Regions',
          //   icon: 'fas fa-globe-stand',
          //   route: '/regions',
          // },
          {
            id: 'about',
            label: 'About Us',
            icon: 'fas fa-question-circle',
            route: '/about',
          },
          {
            type: 'divider',
            hide: ['footer'],
          },
          {
            id: 'support',
            label: 'Site Support',
            icon: 'fas fa-user-headset',
            route: '/support',
          },
          {
            id: 'register',
            label: 'Register',
            icon: 'fas fa-arrow-to-top',
            href: `${directoryURL}/register`,
            hide: ['footer'],
          },
          {
            id: 'signIn',
            label: 'Sign In',
            icon: 'fas fa-sign-in',
            href: `${directoryURL}/Account/Login?loginfrom=www.vaks.org.au`,
            hide: ['footer'],
          },
          {
            id: 'reset',
            label: 'Clear Settings & Restart',
            icon: 'fas fa-undo',
            function: 'reset',
            hide: ['footer'],
          },
        ],
      },
    },

    search: {
      tabs: [
        // {
        //   id: 'services',
        //   title: 'Services',
        //   icon: 'fas fa-building',
        //   component: 'SearchResults',
        //   map: true,
        //   options: {
        //     search: 'services',
        //   },
        // },
        {
          id: 'events',
          title: 'Events',
          icon: 'fas fa-calendar-alt',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'events',
          },
        },
        {
          id: 'favourites',
          title: 'Favourites',
          icon: 'fas fa-star',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'favourites',
          },
        },
      ],

      desktop: {
        // mapFooter: {
        //   sections: [
        //     {
        //       component: 'Sponsors',
        //       props: {
        //         itemSet: {
        //           xs: 'default',
        //           ph: 'nohhs',
        //         },
        //       },
        //     },
        //     { component: 'Acknowledgement' },
        //   ],
        // },
      },

      map: {
        locationZoom: 15,
        selectionZoom: 17,
        style: mapStyle,
      },

      parameters: {
        query: {
          id: 'query',
          type: 'query',
          label: 'Search',
          icon: 'far fa-search',
        },
        location: {
          id: 'location',
          type: 'location',
          label: 'Location',
          icon: 'fas fa-map-marker-question',
        },
        radius: {
          id: 'radius',
          type: 'radius',
          label: 'Radius',
          icon: 'far fa-running',
        },
        dateRange: {
          id: 'dateRange',
          type: 'dateRange',
          label: 'Dates (Events)',
          icon: 'far fa-clock',
          question: 'When?',
        },
        categories: {
          id: 'categories',
          type: 'categoryButtons',
          label: 'Categories',
          icon: 'far fa-stream',
        },
      },

      parameterGroups: {
        desktop: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'dateRange' },
            // { param: 'categories' },
          ],
        },
        mobileSearch: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'dateRange' },
          ],
        },
        // mobileCategories: {
        //   controls: [
        //     { param: 'categories' },
        //   ],
        // },
      },

      resultsList: {
        default: {
          sections: [
            // {
            //   type: 'alertBlock',
            //   items: [
            //     { component: 'CovidAlert' },
            //   ],
            // },
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'statusHeaderBlock',
              items: [
                { component: 'StatusHeader' },
                { component: 'Promotions' },
              ],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'Status' }, { component: 'SiteSupport' }],
            },
            // {
            //   type: 'footerBlock',
            //   items: [
            //     { component: 'Divider' },
            //     {
            //       component: 'Sponsors',
            //       props: {
            //         itemSet: {
            //           xs: 'default',
            //           ph: 'nohhs',
            //         },
            //       },
            //     },
            //     { component: 'Acknowledgement' },
            //   ],
            // },
          ],
        },
      },

      resultItem: {
        service: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'directory',
                showDistance: true,
              },
            },
            {
              component: 'Title',
            },
            {
              component: 'Details',
              fields: [
                { key: 'operatingDays', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
              hideVariant: 'map',
            },
            {
              component: 'HealthEngine',
              hideVariant: 'map',
            },
          ],
        },
        event: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'diary',
                showDistance: true,
              },
            },
            {
              component: 'Title',
            },
            {
              component: 'Details',
              fields: [
                { key: 'dateString', icon: 'far fa-calendar' },
                { key: 'time', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
            },
          ],
        },
      },
    }, // search

    print: { isShowPrintButton: true },
  }, // ui

  assets,
  survey,
}

export default config

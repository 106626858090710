import { grey, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const colors = {
  // primary: '#c9bc59',
  // secondary: '#c9bc59',
  primary: '#5f7a89',
  secondary: '#ffd900',
  grey: '#5f7a89',
  text: {
    primary: grey[700],
  },
}

const dimensions = {
  parameters: 380,
  results: 450,
  outlinedInput: {
    root: {
      padding: 0.5,
    },
    input: {
      padding: 0.75,
    },
  },
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    ph: 740,
    md: 900,
    lg: 1200,
    tb: 1350,
    xl: 1536,
  },
}

const theme = createTheme({
  breakpoints,
})

export const themes = {
  base: {
    breakpoints,

    palette: {
      primary: {
        main: colors.primary,
        contrastText: 'white',
      },
      secondary: {
        main: colors.secondary,
        contrastText: 'white',
      },
      default: {
        main: grey[200],
        light: grey[100],
        dark: grey[400],
      },
      grey: {
        main: grey[700],
        light: grey[400],
        dark: grey[600],
      },
      alert: {
        main: colors.red,
      },
      background: {
        default: 'white',
        paper: 'white',
        sponsors: 'white',
        footer: grey[200],
        footerAcknowledgement: grey[300],
      },
      text: {
        primary: colors.text.primary,
      },
      covidAlert: {
        main: colors.red,
        contrastText: 'white',
      },
      hbLightBlue: {
        main: colors.lightBlue,
        contrastText: 'white',
      },
      hbYellow: {
        main: colors.secondary,
        contrastText: colors.text.primary,
      },
      hbGrey: {
        main: colors.grey,
        contrastText: 'white',
      },
      hbBlue: {
        main: colors.primary,
        contrastText: 'white',
      },
      hbGreen: {
        main: colors.green,
        contrastText: 'white',
      },
      hbLightGrey: {
        main: grey[100],
      },
    },

    typography: {
      fontSize: 14,
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(', '),
    },

    components: {
      MuiCssBaseline: {
        '@global': {},
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          containedSizeSmall: {
            fontSize: '0.86rem',
            fontWeight: 400,
          },
          containedSizeMedium: {
            fontSize: '1rem',
            fontWeight: 400,
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: theme.spacing(dimensions.outlinedInput.root.padding),
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: theme.spacing(1),
            },
          },
          input: {
            padding: theme.spacing(dimensions.outlinedInput.input.padding),
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            padding: theme.spacing(0.25),
            '& .MuiAutocomplete-input': {
              padding: theme.spacing(1),
            },
          },
        },
        variants: [
          {
            props: { variant: 'locationInput' },
            style: {
              '& .MuiOutlinedInput-root': {
                padding: theme.spacing(0.25),
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRightWidth: 0,
                  '.Mui-focused&': {
                    borderRightWidth: 0,
                  },
                },
              },
            },
          },
        ],
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 46,
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: theme.spacing(0, 0),
            minHeight: 46,
            [theme.breakpoints.up('sm')]: {
              minWidth: 120,
            },
          },
        },
      },

      // AppBar

      AMSAppBar: {
        props: {
          elevation: 0,
          position: 'static',
        },
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: colors.text.primary,
            boxShadow: '0 0px 4px 0px rgba(0, 0, 0, 0.3)',
            flexShrink: 1,
          },
          row: {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0, 1),
            },
          },
        },
      },

      AMSAppBarGroup: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0.5),
            },
          },
          left: {
            [theme.breakpoints.down('sm')]: {
              flex: '1 0',
            },
          },
          fill: {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
          right: {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
        },
      },

      AMSMenuButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.8rem',
            },
          },
        },
      },

      AMSAppBarTitle: {
        styleOverrides: {
          logo: {
            width: 185,
            height: 33,
            [theme.breakpoints.down('sm')]: {
              width: 158,
              height: 28,
            },
          },
          title: {
            color: colors.text.primary,
            fontWeight: 400,
          },
        },
      },
    },

    // desktop search

    AMSDesktopSearch: {
      styleOverrides: {
        parameterDrawer: {
          minWidth: dimensions.parameters,
          maxWidth: dimensions.parameters,
        },
        parameters: {
          width: dimensions.parameters,
        },
        results: {
          width: dimensions.results,
          [theme.breakpoints.between('ph', 'md')]: {
            width: 'calc(100vw / 2)',
          },
        },
      },
    },

    AMSDesktopSearchToolbar: {
      toolbarButton: {
        fontSize: '0.92rem',
      },
    },
  },
}

// A custom theme for this app
const theme_ = {
  palette: {
    primary: {
      main: '#c9bc59',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c9bc59',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#666',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'],
  },

  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {},
  //   },

  //   MuiOutlinedInput: {
  //     root: {
  //       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //         border: `1px solid ${colors.primary}`,
  //       },
  //     },
  //     input: {
  //       padding: '12px 12px',
  //     },
  //   },

  //   AMSAppBar: {
  //     root: {
  //       backgroundColor: '#dadada',
  //       color: '#666',
  //       // borderBottom: '1px solid #ddd',
  //     },
  //     logo: {},
  //     title: {
  //       fontWeight: 400,
  //       [theme.breakpoints.down('xs')]: {
  //         display: 'none',
  //       },
  //     },
  //     button: {
  //       textTransform: 'none',
  //       color: '#666',
  //     },
  //   },
  // },
}

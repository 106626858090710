import shared from '../shared/assets'
import textLogo from './assets/images/vaks-text-logo.svg'
const { cie, headerIcons, flags, mapMarkers } = shared.defaults

import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

export const assets = {
  logo: textLogo,
  // myClosestFeedIcon,
  // accessTheListIcon,
  // mySavedListIcon,
  // welfareServiceProvidersIcon,
  cie,
  flags,
  headerIcons,
  mapMarkers,
}
